import React from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'

import { AppPageProps } from '../types'
import { breakpoints } from '../styles/variables'
import { HomeHero } from '../components/HomeHero'
import { HomeHeroLeadJa } from '../components/HomeHeroLeadJa'
import { IndexPageQuery } from '../../types/graphql-types'
import { Layout } from '../components/Layout'
import { LoadingContext } from '../contexts/LoadingContext'
import { PhilosophyHomeList } from '../components/PhilosophyHomeList'
import { PhilosophyItem } from '../components/PhilosophyItem'
import { ProductHomeList } from '../components/ProductHomeList'
import { ProductItem } from '../components/ProductItem'
import { SectionList } from '../components/SectionList'

export const query = graphql`
  query IndexPage {
    heroMountainMobile: file(
      relativePath: { eq: "images/home-hero-mountain@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroMountainTablet: file(
      relativePath: { eq: "images/home-hero-mountain@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroCaskMobile: file(
      relativePath: { eq: "images/home-hero-cask@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroCaskTablet: file(
      relativePath: { eq: "images/home-hero-cask@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroBuildingMobile: file(
      relativePath: { eq: "images/home-hero-building@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroBuildingTablet: file(
      relativePath: { eq: "images/home-hero-building@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whiskyLight: file(
      relativePath: { eq: "images/products-whisky@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whiskyDark: file(relativePath: { eq: "images/products-whisky@dark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritsLight: file(
      relativePath: { eq: "images/products-spirits@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritsDark: file(
      relativePath: { eq: "images/products-spirits@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shochuLight: file(
      relativePath: { eq: "images/products-shochu@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shochuDark: file(relativePath: { eq: "images/products-shochu@dark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landMobile: file(
      relativePath: { eq: "images/philosophy-land-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landTablet: file(
      relativePath: { eq: "images/philosophy-land-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritMobile: file(
      relativePath: { eq: "images/philosophy-spirit-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritTablet: file(
      relativePath: { eq: "images/philosophy-spirit-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface IndexPageProps extends AppPageProps {
  data: IndexPageQuery
}

const IndexPage: React.FC<IndexPageProps> = ({
  pathContext: { langKey },
  data: {
    heroMountainMobile,
    heroMountainTablet,
    heroCaskMobile,
    heroCaskTablet,
    heroBuildingMobile,
    heroBuildingTablet,
    whiskyDark,
    whiskyLight,
    spiritsLight,
    spiritsDark,
    shochuLight,
    shochuDark,
    landMobile,
    landTablet,
    spiritMobile,
    spiritTablet,
  },
}) => (
  <Layout langKey={langKey} headerMode="home">
    <SectionList>
      <LoadingContext.Provider value="eager">
        <HomeHero
          fluids={[
            {
              mobile: heroMountainMobile?.childImageSharp?.fluid as FluidObject,
              tablet: heroMountainTablet?.childImageSharp?.fluid as FluidObject,
            },
            {
              mobile: heroCaskMobile?.childImageSharp?.fluid as FluidObject,
              tablet: heroCaskTablet?.childImageSharp?.fluid as FluidObject,
            },
            {
              mobile: heroBuildingMobile?.childImageSharp?.fluid as FluidObject,
              tablet: heroBuildingTablet?.childImageSharp?.fluid as FluidObject,
            },
          ]}
        >
          <HomeHeroLeadJa />
        </HomeHero>
      </LoadingContext.Provider>
      <ProductHomeList>
        <ProductItem
          to="/products/whisky/"
          fluidDark={whiskyDark?.childImageSharp?.fluid as FluidObject}
          fluidLight={whiskyLight?.childImageSharp?.fluid as FluidObject}
          sublabel="ウイスキー"
          label="Whisky"
          imageSizes={`(min-width: ${breakpoints.tablet}px) 18vw, 70vw`}
        />
        <ProductItem
          to="/products/spirits/"
          fluidDark={spiritsDark?.childImageSharp?.fluid as FluidObject}
          fluidLight={spiritsLight?.childImageSharp?.fluid as FluidObject}
          sublabel="スピリッツ"
          label="Spirits"
          imageSizes={`(min-width: ${breakpoints.tablet}px) 18vw, 70vw`}
        />
        <ProductItem
          to="/products/shochu/"
          fluidDark={shochuDark?.childImageSharp?.fluid as FluidObject}
          fluidLight={shochuLight?.childImageSharp?.fluid as FluidObject}
          sublabel="焼酎"
          label="Shochu"
          imageSizes={`(min-width: ${breakpoints.tablet}px) 18vw, 70vw`}
        />
      </ProductHomeList>
      <PhilosophyHomeList>
        <PhilosophyItem
          to="/philosophy/land/"
          fluidMobile={landMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={landTablet?.childImageSharp?.fluid as FluidObject}
          sublabel="土地"
          label="Land"
          imageSizes="50vw"
        />
        <PhilosophyItem
          to="/philosophy/spirit/"
          fluidMobile={spiritMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={spiritTablet?.childImageSharp?.fluid as FluidObject}
          sublabel="精神"
          label="Spirit"
          imageSizes="50vw"
        />
      </PhilosophyHomeList>
    </SectionList>
  </Layout>
)

export default IndexPage
