import React from 'react'
import { animated } from 'react-spring'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'
import { useTextEffect } from '../hooks/useTextEffect'

const StyledHomeHeroLeadJa = styled.div`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-family-serif);
  font-size: 1.6rem;
  line-height: 2;
  position: relative;
  white-space: nowrap;
  writing-mode: vertical-rl;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 2rem;
  }
`

const Lead = styled.div`
  display: flex;
  overflow: hidden;
  position: absolute;
  right: 2rem;

  @media (min-width: ${breakpoints.tablet}px) {
    bottom: 3rem;
  }
`

const Inner = styled(Lead)`
  bottom: 0;
  color: var(--color-ground-1000);
  height: 5em;

  @media (prefers-color-scheme: dark) {
    color: inherit;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    height: auto;
  }
`

const Outer = styled(Lead)`
  align-items: flex-end;
  flex-direction: column;
  height: 4em;

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const HomeHeroLeadJa: React.FC = () => {
  const list = useTextEffect('水の湧く山の奥深く\n水と森の蒸留所\n尾鈴山蒸留所')

  return (
    <StyledHomeHeroLeadJa>
      <Inner>
        <div>
          {list.map((props, i) =>
            props ? (
              // @ts-ignore
              <animated.span key={i.toString()} style={props.style}>
                {props.char}
              </animated.span>
            ) : (
              <br key={i.toString()} />
            )
          )}
        </div>
      </Inner>
      <Outer>
        <div>
          {list.map((props, i) =>
            props ? (
              // @ts-ignore
              <animated.span key={i.toString()} style={props.style}>
                {props.char}
              </animated.span>
            ) : (
              <br key={i.toString()} />
            )
          )}
        </div>
      </Outer>
    </StyledHomeHeroLeadJa>
  )
}
